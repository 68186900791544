import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HomeIcon, TransformIcon, AvatarIcon } from '@radix-ui/react-icons';
import '../css/BottomBar.css'

const BottomBar = () => {
  const location = useLocation();

  return (
    <div className="bottom-bar">
      <Link to="/" className={location.pathname === '/home' ? 'active' : 'inactive'}>
        <HomeIcon />
      </Link>
      <Link to="/collection" className={location.pathname === '/collection' ? 'active' : 'inactive'}>
        <TransformIcon />
      </Link>
      <Link to="/settings" className={location.pathname === '/settings' ? 'active' : 'inactive'}>
        <AvatarIcon />
      </Link>
    </div>
  );
};

export default BottomBar;
