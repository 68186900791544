import React, { useEffect, useState } from 'react';
import { Flex, Text } from '@radix-ui/themes';
import ethereumIcon from '../images/ethereum.svg';
import polygonIcon from '../images/polygon.svg';
import arbitrumIcon from '../images/arbitrum.svg';
import baseIcon from '../images/base.svg';
import sepoliaIcon from '../images/sepolia.svg';
import { fetchData } from '../utils/fetchData';
import '../css/Portfolio.css';

const Portfolio = ({ address }) => {
  const [data, setData] = useState({ prices: {}, balances: {} });

  useEffect(() => {
    const getData = async () => {
      const result = await fetchData(address);
      setData(result);
    };

    getData();
  }, [address]);

  const assets = [
    { name: 'Ethereum', icon: ethereumIcon, chain: 'ETH', symbol: 'ETH' },
    { name: 'Polygon', icon: polygonIcon, chain: 'MATIC', symbol: 'MATIC' },
    { name: 'Arbitrum', icon: arbitrumIcon, chain: 'ARB', symbol: 'ARB' },
    { name: 'Base', icon: baseIcon, chain: 'BASE', symbol: 'BASE' },
    { name: 'Sepolia', icon: sepoliaIcon, chain: 'SEPOLIA', symbol: 'SEPOLIA' },
  ];

  return (
    <Flex direction="column" align="center" className="portfolio-container">
      {/*
      <Flex align="left" style={{width: "95%"}}>
        <Text size="3" weight="bold">Portfolio</Text>
      </Flex>
      */}
      {assets.map((asset) => (
        <Flex direction="row" align="center" justify="between" gap="2" key={asset.name} className="portfolio-item" style={{width: "100%", height: "95%"}}>
          <Flex direction="row" align="center" gap="2">
            <img src={asset.icon} alt={`${asset.name} icon`} style={{ width: '24px', height: '24px' }} />
            <Text size="2">{asset.name}</Text>
          </Flex>
          <Text size="1">${data.prices[asset.chain]?.USD?.toFixed(2) || '0.00'}</Text>
          <Text size="1">{data.balances[asset.chain]?.toFixed(2) || '0.00'} {asset.symbol}</Text>
        </Flex>
      ))}
    </Flex>
  );
};

export default Portfolio;
