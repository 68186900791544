import React from 'react';
import { Flex, Button, Text, Link } from '@radix-ui/themes';

const AuthBottomBar = ({ isLogin, toggleAuthMode }) => {
  return (
    <Flex className="auth-bottom-bar" justify="between" align="center" >
      <Text size="2">{isLogin ? "Don't have an account?" : 'Already have an account?'}</Text>
      <Link onClick={toggleAuthMode} size="2">
        {isLogin ? 'Sign Up' : 'Log In'}
      </Link>
    </Flex>
  );
};

export default AuthBottomBar;
