import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { Flex, Button, Text } from '@radix-ui/themes';
import { CheckCircledIcon, CrossCircledIcon } from '@radix-ui/react-icons';
import '../css/Modal.css';

//0x19e6744397c1a8efd8054bc66ffbaa19a4a1f9c7

const PhantomMintModal = ({ onClose, onProceed, loading, success, signingError }) => {

  return (
    <Dialog.Root open={true} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContent">
          <Dialog.Title style={{ display: 'none' }}>---</Dialog.Title>
          {loading ? (
            <Flex direction="column" align="center" justify="center" style={{ height: '100%' }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{ width: 40, height: 40, marginBottom: 20 }}
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
              >
                <circle
                  cx="50"
                  cy="50"
                  fill="none"
                  stroke="green"
                  strokeWidth="8"
                  r="35"
                  strokeDasharray="164.93361431346415 56.97787143782138"
                >
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    repeatCount="indefinite"
                    dur="1s"
                    values="0 50 50;360 50 50"
                    keyTimes="0;1"
                  />
                </circle>
              </svg>
              <div>Loading...</div>
            </Flex>
          ) : success ? (
            <Flex direction="column" align="center" justify="center" style={{ height: '100%' }}>
              <CheckCircledIcon color="green" style={{ width: 40, height: 40, marginBottom: 20 }} />
              <div>Minted Successfully!</div>
            </Flex>
          ) : signingError ? (
            <Flex direction="column" align="center" justify="center" style={{ height: '100%' }}>
              <CrossCircledIcon color="red" style={{ width: 40, height: 40, marginBottom: 20 }} />
              <div>Error: {signingError}</div>
            </Flex>
          ) : (
            <Flex direction="column" align="center" justify="center" style={{ height: '100%' }}>
              <button onClick={onProceed} className="send-button">Mint Event NFT Now</button> {/* Add Mint Now Button */}
            </Flex>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default PhantomMintModal;
