import React, { useState, useEffect } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { Flex, Button, Text } from '@radix-ui/themes';
import '../css/Modal.css';

const QuantityModal = ({ onClose, onProceed, imageUrl, price }) => {
  const [quantity, setQuantity] = useState(1);
  const [totalPrice, setTotalPrice] = useState(price);

  useEffect(() => {
    setTotalPrice(price * quantity);
  }, [quantity, price]);

  const handleIncrement = () => {
    setQuantity((prevQuantity) => (prevQuantity < 100 ? prevQuantity + 1 : prevQuantity));
  };

  const handleDecrement = () => {
    setQuantity((prevQuantity) => (prevQuantity > 1 ? prevQuantity - 1 : prevQuantity));
  };

  const handleProceed = () => {
    onProceed(quantity);
    onClose();
  };

  return (
    <Dialog.Root open={true} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContent">
          <Dialog.Title style={{ display: 'none' }}>---</Dialog.Title>
          <Flex direction="column" align="center" justify="top" style={{ height: '100%' }}>
            <img src={imageUrl} alt="NFT" style={{ width: '8em', height: '8em', objectFit: 'contain', marginBottom: '5px' }} />
            {/*<Text size="2" style={{ marginBottom: '20px' }}>{`Price: ${price} USD`}</Text>*/}
            <div className="QuantityContainer">
              <button className="QuantityButton" onClick={handleDecrement}>-</button>
              <span className="QuantityDisplay">{quantity}</span>
              <button className="QuantityButton" onClick={handleIncrement}>+</button>
            </div>
            <Text size="2" style={{ margin: '5px 0' }}>{`Total: ¥${totalPrice}`}</Text>
            <button className="send-button" onClick={handleProceed}>
              Proceed to Checkout
            </button>
          </Flex>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default QuantityModal;
