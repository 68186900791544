import React, { useState } from 'react';
import { Flex, Text, IconButton } from '@radix-ui/themes';
import { ClipboardCopyIcon } from '@radix-ui/react-icons';

const WalletAddress = ({ address }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(address);
    setCopied(true);
    setTimeout(() => setCopied(false), 500); // Reset after 2 seconds
  };

  const shortenedAddress = `${address.slice(0, 6)}...${address.slice(-4)}`;

  return (
    <Flex direction="row" align="center" gap="2">
      <Text size={"5"} weight={"medium"}>{shortenedAddress}</Text>
      {/*<IconButton onClick={handleCopy} variant="ghost">*/}
        <ClipboardCopyIcon onClick={handleCopy}/>
      {/*</IconButton>*/}
      {copied && <Text size="1" style={{ marginLeft: '0.5rem' }}>Copied!</Text>}
    </Flex>
  );
};

export default WalletAddress;
