import React, { useContext, useState, useEffect } from 'react';
import { Flex, Button, Text } from '@radix-ui/themes';
import { SunIcon, MoonIcon } from '@radix-ui/react-icons';
import { ThemeContext } from '../context/ThemeContext';
import logo from '../images/logo.svg';
import logoDark from '../images/logo-dark.svg';
import ChainModal from '../components/ChainModal';
import ethereumIcon from '../images/ethereum.svg';
import polygonIcon from '../images/polygon.svg';
import arbitrumIcon from '../images/arbitrum.svg';
import baseIcon from '../images/base.svg';
import sepoliaIcon from '../images/sepolia.svg';

const chainIcons = {
  Ethereum: ethereumIcon,
  Polygon: polygonIcon,
  Arbitrum: arbitrumIcon,
  Base: baseIcon,
  Sepolia: sepoliaIcon,
};

const TopBar = () => {
  const { isDarkMode, toggleTheme } = useContext(ThemeContext);
  const [showChainModal, setShowChainModal] = useState(false);
  const [selectedChain, setSelectedChain] = useState('Ethereum');

  useEffect(() => {
    const storedChain = localStorage.getItem('selectedChain');
    if (storedChain) {
      setSelectedChain(storedChain);
    }
  }, []);

  const handleChainSelect = (chainName) => {
    setSelectedChain(chainName);
    setShowChainModal(false);
  };

  return (
    <Flex justify="between" align="center" className="flex-row" style={{ padding: '10px', backgroundColor: 'var(--background-color)' }}>
      <img src={isDarkMode ? logoDark : logo} alt="logo" className="App-logo" />
      {/*<Text size={"3"}>tancre</Text>*/}


      <Flex gap="2">
        <Button 
          style={{
            alignItems: "center",
            width: "2.5rem",
            height: "2.5rem"
          }}
          onClick={() => setShowChainModal(true)}
          className="radix-button"
        >
          <img src={chainIcons[selectedChain]} alt={`${selectedChain} icon`} style={{ width: '20px', height: '20px' }} />
        </Button>

        <Button 
          style={{
            alignItems: "center",
            width: "2.5rem",
            height: "2.5rem"
          }}
          onClick={toggleTheme} 
          className="radix-button"
        >
          {isDarkMode ? <SunIcon style={{ width: 30, height: 30 }} /> : <MoonIcon style={{ width: 30, height: 30 }} />}
        </Button>
      </Flex>
      {showChainModal && <ChainModal onClose={() => setShowChainModal(false)} onSelectChain={handleChainSelect} />}
    </Flex>
  );
};

export default TopBar;