import React, { useState } from 'react';
import { Flex, Button, Separator, Text } from '@radix-ui/themes';
import { ClipboardCopyIcon } from '@radix-ui/react-icons';
import { useNavigate } from 'react-router-dom';
import { WebAuthn } from 'othello-webauthn-wallet';
import TopBar from '../components/TopBar';

const Settings = ({ address }) => {
  const [privateKeyHex, setPrivateKeyHex] = useState(null);
  const shortenedAddress = `${address.slice(0, 6)}...${address.slice(-4)}`;
  const navigate = useNavigate();

  const handleCopy = () => {
    navigator.clipboard.writeText(address);
  };

  const handleCopyPrivateKey = () => {
    if (privateKeyHex) {
      navigator.clipboard.writeText(privateKeyHex);
    }
  };

  const handleExportPrivateKey = async () => {
    try {
      const challenge = new Uint8Array(Buffer.from("random", 'hex'));
      const credential = await WebAuthn.get(challenge); // to get privatekey of wallet address

      const privateKeyUint8Array = credential.privateKey;
      const privateKeyHex = uint8ArrayToHex(privateKeyUint8Array);

      if (credential.walletAddress !== address) {
        throw new Error('You are using a different credential');
      }

      setPrivateKeyHex(privateKeyHex);

    } catch (error) {
      console.error('Error exporting private key:', error);
    }
  };

  const uint8ArrayToHex = (uint8Array) => {
    return Array.from(uint8Array)
      .map(byte => byte.toString(16).padStart(2, '0'))
      .join('');
  };

  const handleLogout = () => {
    // Clear local storage
    localStorage.clear();
    // Dispatch storage event to update state
    window.dispatchEvent(new Event('storage'));
    // Force navigation to auth page
    navigate('/auth', { replace: true });
  };

  const shortenPrivateKey = (key) => {
    return `${key.slice(0, 6)}...${key.slice(-4)}`;
  };

  return (
    <Flex direction="column" align="center" className="flex-column" style={{ height: '100vh', backgroundColor: 'var(--background-color)', color: 'var(--text-color)' }}>
      <TopBar />

      <Flex direction="row" align="center" justify="between" style={{ marginTop: '30px', width: "90%" }} className="button-grid">
        <Button style={{width: "60%"}} onClick={handleCopy}>
          {shortenedAddress}
          <ClipboardCopyIcon />
        </Button>
        <Button className="radix-button-logout" style={{ width: "30%" }} onClick={handleLogout}>Log Out</Button>
      </Flex>

      <Separator className="separator" style={{ width: "90%" }} />

      <Flex style={{ marginTop: '30px', width: "90%" }}>
        <Button onClick={handleExportPrivateKey}>Get Privatekey</Button>
      </Flex>

      {privateKeyHex && (
        <Flex direction="row" align="center" justify="between" style={{ marginTop: '30px', width: "90%" }} className="button-grid">
          <Button style={{width: "60%"}} onClick={handleCopyPrivateKey}>
            {shortenPrivateKey(privateKeyHex)}
            <ClipboardCopyIcon />
          </Button>
        </Flex>
      )}
    </Flex>
  );
};

export default Settings;