import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { ThemeProvider } from './context/ThemeContext';
import Home from './pages/Home';
import Settings from './pages/Settings';
import Collection from './pages/Collection';
import CollectionDetail from './pages/CollectionDetail';
import AuthPage from './pages/AuthPage';
import BottomBar from './components/BottomBar';
import './css/App.css';

function App() {
  const [walletAddress, setWalletAddress] = useState(localStorage.getItem('walletAddress'));
  const [redirectParams, setRedirectParams] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleStorageChange = () => {
      setWalletAddress(localStorage.getItem('walletAddress'));
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    setWalletAddress(localStorage.getItem('walletAddress'));
  }, []);

  useEffect(() => {
    if (!walletAddress && location.search) {
      setRedirectParams(location.search);
    }
  }, [walletAddress, location]);

  useEffect(() => {
    if (walletAddress && redirectParams) {
      navigate(`/home${redirectParams}`, { replace: true });
    }
  }, [walletAddress, redirectParams, navigate]);

  return (
    <ThemeProvider>
      <div className="App">
        <Routes>
          {walletAddress ? (
            <>
              <Route path="/home" element={<Home address={walletAddress} />} />
              <Route path="/settings" element={<Settings address={walletAddress} />} />
              <Route path="/collection" element={<Collection address={walletAddress} />} />
              <Route path="/collection-detail" element={<CollectionDetail />} />
              <Route path="*" element={<Navigate to={`/home${location.search}`} replace />} />
            </>
          ) : (
            <>
              <Route path="/auth" element={<AuthPage />} />
              <Route path="*" element={<Navigate to={`/auth${location.search}`} replace />} />
            </>
          )}
        </Routes>
        {walletAddress && <BottomBar />}
      </div>
    </ThemeProvider>
  );
}

function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWrapper;
