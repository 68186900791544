import React, { useState, useEffect } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { Flex, Text } from '@radix-ui/themes';
import { ethers } from 'ethers';
import { CheckCircledIcon, CrossCircledIcon } from '@radix-ui/react-icons';
import '../css/Modal.css';
import { fetchData } from '../utils/fetchData'; // Assuming you have a fetchData function
import { WebAuthn } from 'othello-webauthn-wallet';

const alchemyProjectId = process.env.REACT_APP_ALCHEMY_PROJECT_ID;

const web3Providers = {
  Ethereum: new ethers.providers.JsonRpcProvider(`https://eth-mainnet.g.alchemy.com/v2/${alchemyProjectId}`),
  Polygon: new ethers.providers.JsonRpcProvider(`https://polygon-mainnet.g.alchemy.com/v2/${alchemyProjectId}`),
  Arbitrum: new ethers.providers.JsonRpcProvider(`https://arb-mainnet.g.alchemy.com/v2/${alchemyProjectId}`),
  Base: new ethers.providers.JsonRpcProvider(`https://base-mainnet.g.alchemy.com/v2/${alchemyProjectId}`),
  Sepolia: new ethers.providers.JsonRpcProvider(`https://eth-sepolia.g.alchemy.com/v2/${alchemyProjectId}`)
};

const SendModal = ({ onClose, walletAddress }) => {
  const [destinationAddress, setDestinationAddress] = useState('');
  const [amount, setAmount] = useState('');
  const [balance, setBalance] = useState('-.--');
  const [currency, setCurrency] = useState('---');
  const [loadingScan, setLoadingScan] = useState(false);
  const [isSigned, setIsSigned] = useState(false);
  const [signingError, setSigningError] = useState('');

  useEffect(() => {
    const selectedChain = localStorage.getItem('selectedChain');
    const chains = {
      Ethereum: 'ETH',
      Polygon: 'MATIC',
      Arbitrum: 'ARB',
      Base: 'BASE',
      Sepolia: 'SEPOLIA'
    };

    setCurrency(chains[selectedChain]);

    const getData = async () => {
      const data = await fetchData(walletAddress); // Fetch data from your backend or API
      const chainSymbol = chains[selectedChain];
      const chainBalance = data.balances[chainSymbol];
      setBalance(chainBalance ? chainBalance.toFixed(2) : '0.00');
    };

    getData();
  }, [walletAddress]);

  const handleSend = async () => {
    setLoadingScan(true);
    setIsSigned(false);
    setSigningError('');

    try {
      const selectedChain = localStorage.getItem('selectedChain');
      const provider = web3Providers[selectedChain];

      const challenge = new Uint8Array(Buffer.from("random", 'hex'));
      const credential = await WebAuthn.get(challenge); // to get privatekey of wallet address

      const privateKeyUint8Array = credential.privateKey;
      const privateKeyHex = uint8ArrayToHex(privateKeyUint8Array);

      if (credential.walletAddress !== walletAddress) {
        throw new Error('You are using a different credential');
      }

      // Create a wallet instance with the private key
      const wallet = new ethers.Wallet(privateKeyHex);
      const walletWithProvider = wallet.connect(provider);

      // Create and send the transaction
      const transaction = {
        to: destinationAddress,
        value: ethers.utils.parseEther(amount),
        gasLimit: ethers.utils.hexlify(21000), // standard gas limit for ETH transfer
      };

      const tx = await walletWithProvider.sendTransaction(transaction);
      await tx.wait();

      setLoadingScan(false);
      setIsSigned(true);
    } catch (error) {
      setLoadingScan(false);
      setSigningError(error.message);
    }

    // onClose();
  };

  const uint8ArrayToHex = (uint8Array) => {
    return Array.from(uint8Array)
      .map(byte => byte.toString(16).padStart(2, '0'))
      .join('');
  };

  return (
    <Dialog.Root open={true} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContent">
          <Dialog.Title style={{ display: 'none' }}>---</Dialog.Title>
            {loadingScan && (
              <Flex direction="column" align="center" justify="center" style={{ height: '100%' }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ width: 40, height: 40, marginBottom: 20 }}
                  viewBox="0 0 100 100"
                  preserveAspectRatio="xMidYMid"
                >
                  <circle
                    cx="50"
                    cy="50"
                    fill="none"
                    stroke="green"
                    strokeWidth="8"
                    r="35"
                    strokeDasharray="164.93361431346415 56.97787143782138"
                  >
                    <animateTransform
                      attributeName="transform"
                      type="rotate"
                      repeatCount="indefinite"
                      dur="1s"
                      values="0 50 50;360 50 50"
                      keyTimes="0;1"
                    />
                  </circle>
                </svg>
                <div>Processing...</div>
              </Flex>
            )}

            {isSigned && (
              <Flex direction="column" align="center" justify="center" style={{ height: '100%' }}>
                <CheckCircledIcon color='green' style={{ width: 40, height: 40, marginBottom: 20 }} />
                <div>Transaction Successfull</div>
              </Flex>
            )}

            {signingError && (
              <Flex direction="column" align="center" justify="center" style={{ height: '100%' }}>
                <CrossCircledIcon color='red' style={{ width: 40, height: 40, marginBottom: 20 }} />
                <div>Error: {signingError}</div>
              </Flex>
            )}

            {!loadingScan && !isSigned && !signingError && (
              <Flex direction="column" align="center" justify="between" style={{ height: '100%' }}>
                <Flex direction="column" style={{ width: '100%' }}>
                  <input
                    type="text"
                    placeholder="To : 0x123...789"
                    value={destinationAddress}
                    onChange={(e) => setDestinationAddress(e.target.value)}
                    className="input-field"
                  />

                  <input
                    type="text"
                    placeholder="Amount : 0.01"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    className="input-field"
                  />

                  <div align="right">
                    <span id='availableFunds'>{balance}</span> <span id='currency'>{currency}</span> available
                  </div>
                </Flex>

                <button onClick={handleSend} className="send-button">
                  Send
                </button>
              </Flex>
            )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default SendModal;