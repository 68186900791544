import React, { useState } from 'react';
import { Flex, Button } from '@radix-ui/themes';
import Login from './Login';
import Signup from './Signup';
import TopBarAuth from '../components/TopBarAuth';
import AuthBottomBar from '../components/AuthBottomBar';

const AuthPage = () => {
  const [isLogin, setIsLogin] = useState(true);

  const toggleAuthMode = () => {
    setIsLogin((prev) => !prev);
  };

  return (
    <Flex direction="column" style={{ height: '100vh', backgroundColor: 'var(--background-color)', color: 'var(--text-color)' }}>
      <TopBarAuth />
      <Flex direction="column" align="center" justify="center" style={{ flex: 1 }}>
        {isLogin ? <Login /> : <Signup />}
      </Flex>
      <AuthBottomBar isLogin={isLogin} toggleAuthMode={toggleAuthMode} />
    </Flex>
  );
};

export default AuthPage;
