import React, { useState, useEffect } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { Flex } from '@radix-ui/themes';
import ReactQrReader from 'react-qr-reader-es6';
import '../css/Modal.css';
import { WebAuthn } from 'othello-webauthn-wallet';
import { CheckCircledIcon, CrossCircledIcon } from '@radix-ui/react-icons';

const SignModal = ({ onClose, walletAddress }) => {
  const [scanResult, setScanResult] = useState('');
  const [loadingScan, setLoadingScan] = useState(false);
  const [signingError, setSigningError] = useState(null);
  const [isSigned, setIsSigned] = useState(false);

  useEffect(() => {
    if (scanResult) {
      handleSign();
    }
  }, [scanResult]);

  const handleScan = (scanData) => {
    if (scanData) {
      console.log('Loaded data:', scanData);
      setScanResult(scanData);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const handleSign = async () => {
    setLoadingScan(true);
    try {
      const challenge = new Uint8Array(Buffer.from("random", 'hex'));
      const credential = await WebAuthn.get(challenge);
      if (credential) {
        const privateKeyUint8Array = credential.privateKey;
        const privateKeyHex = uint8ArrayToHex(privateKeyUint8Array);
        console.log('wallet address', credential.walletAddress);
        console.log('privatekey', privateKeyHex);

        if (credential.walletAddress !== walletAddress) {
          throw new Error('You are using a different credential');
        }

        setIsSigned(true);
      } else {
        throw new Error('Failed to get private key');
      }
    } catch (error) {
      console.error('Signing error:', error);
      setSigningError(error.message);
    } finally {
      setLoadingScan(false);
    }
  };

  const uint8ArrayToHex = (uint8Array) => {
    return Array.from(uint8Array)
      .map(byte => byte.toString(16).padStart(2, '0'))
      .join('');
  };

  return (
    <Dialog.Root open={true} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContent">
          <Dialog.Title style={{ display: 'none' }}>---</Dialog.Title>
          {loadingScan && (
            <Flex direction="column" align="center" justify="center" style={{ height: '100%' }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                style={{ width: 40, height: 40, marginBottom: 20 }}
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
              >
                <circle
                  cx="50"
                  cy="50"
                  fill="none"
                  stroke="green"
                  strokeWidth="8"
                  r="35"
                  strokeDasharray="164.93361431346415 56.97787143782138"
                >
                  <animateTransform
                    attributeName="transform"
                    type="rotate"
                    repeatCount="indefinite"
                    dur="1s"
                    values="0 50 50;360 50 50"
                    keyTimes="0;1"
                  />
                </circle>
              </svg>
              <div>Loading...</div>
            </Flex>
          )}

          {!loadingScan && !isSigned && !scanResult && (
            <Flex direction="column" align="center" justify="center" style={{ height: '100%' }}>
              <ReactQrReader
                delay={300}
                onError={handleError}
                onScan={handleScan}
                style={{ width: "100%" }}
              />
            </Flex>
          )}

          {isSigned && (
            <Flex direction="column" align="center" justify="center" style={{ height: '100%' }}>
              <CheckCircledIcon color='green' style={{ width: 40, height: 40, marginBottom: 20 }} />
              <div>Signed Successfully!</div>
            </Flex>
          )}

          {signingError && (
            <Flex direction="column" align="center" justify="center" style={{ height: '100%' }}>
              <CrossCircledIcon color='red' style={{ width: 40, height: 40, marginBottom: 20 }} />
              <div>Error: {signingError}</div>
            </Flex>
          )}

        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default SignModal;
