import React, { useContext, useState, useEffect } from 'react';
import { Flex, Button, Text } from '@radix-ui/themes';
import { SunIcon, MoonIcon } from '@radix-ui/react-icons';
import { ThemeContext } from '../context/ThemeContext';
import logo from '../images/logo.svg';
import logoDark from '../images/logo-dark.svg';

const TopBarAuth = () => {
  const { isDarkMode, toggleTheme } = useContext(ThemeContext);

  return (
    <Flex justify="between" align="center" className="flex-row" style={{ padding: '10px', backgroundColor: 'var(--background-color)' }}>
        <img src={isDarkMode ? logoDark : logo} alt="logo" className="App-logo" />
        {/*<Text size={"3"}>tancre</Text>*/}
        <Button 
          style={{
            alignItems: "center",
            width: "2.5rem",
            height: "2.5rem"
          }}
          onClick={toggleTheme} 
          className="radix-button"
        >
          {isDarkMode ? <SunIcon style={{ width: 30, height: 30 }} /> : <MoonIcon style={{ width: 30, height: 30 }} />}
        </Button>
    </Flex>
  );
};

export default TopBarAuth;