import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Flex, Text, Button } from '@radix-ui/themes';
import TopBar from '../components/TopBar';
import '../css/Collection.css';

const CollectionDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { nft } = location.state;

  return (
    <Flex direction="column" className="flex-column" style={{ height: '100vh', backgroundColor: 'var(--background-color)', color: 'var(--text-color)' }}>
      <TopBar />
      <Flex direction="column" align="left" className="collection-detail-container">
        <img src={nft.image} alt={nft.name} className="nft-detail-image" />
        <Text size="4" style={{ marginTop: '15px' }}>{nft.name}</Text>
        <Text size="2" style={{ marginTop: '15px' }}>{nft.description}</Text>
      </Flex>
      <Button onClick={() => navigate(-1)} style={{ margin: '20px' }}>Go Back</Button>
    </Flex>
  );
};

export default CollectionDetail;
