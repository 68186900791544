import React, { useEffect, useState } from 'react';
import { Flex, Text, Grid } from '@radix-ui/themes';
import { useNavigate } from 'react-router-dom';
import TopBar from '../components/TopBar';
import '../css/Collection.css';

// https://oth3llo.io/?phantom_mint=true&nft_mint_event_uid=3292e4a9-4e76-11ef-a8f1-0abb45fe4243&contract_address=0xC3314042cBF88585368a301fC8d389328D30105D&token_id=1

const Collection = ({ address }) => {
  const [nfts, setNfts] = useState(new Map());
  const navigate = useNavigate();

  const urlType = process.env.REACT_APP_ENDPOINT_TYPE;

  useEffect(() => {
    const fetchNfts = async () => {
      try {
        const response = await fetch(`https://api.oth3llo.${urlType}/${urlType}/fetchOth3lloCustomerNft`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 's0aPM75bkT1COlcBaOHJ71mOMeReWRJz5UYkmop2'
          },
          body: JSON.stringify({ 
            "walletAddress": address
          }),
        });
        const data = await response.json();
        console.log(data);
        
        const newNfts = new Map();
        data.forEach(item => {
          newNfts.set(item.uid, {
            name: item.nftName,
            description: item.description,
            image: item.imageUrl,
            ...item
          });
        });
        
        // Compare and update local storage
        const updatedNfts = new Map(nfts);
        newNfts.forEach((value, key) => {
          if (!nfts.has(key)) {
            updatedNfts.set(key, value);
          }
        });
        nfts.forEach((value, key) => {
          if (!newNfts.has(key)) {
            updatedNfts.delete(key);
          }
        });

        setNfts(updatedNfts);
      } catch (error) {
        console.error('Error fetching NFTs:', error);
      }
    };

    fetchNfts();
    const interval = setInterval(fetchNfts, 10000); // Fetch every 10 seconds

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [address]);

  const truncate = (text, length) => {
    return text.length > length ? text.substring(0, length) + "..." : text;
  };

  return (
    <Flex direction="column" className="flex-column" style={{ height: '100vh', backgroundColor: 'var(--background-color)', color: 'var(--text-color)' }}>
      <TopBar />
      <Flex direction="column" align="center" className="collection-container">
        {nfts.size === 0 ? (
          <Text size="4" style={{ marginTop: '20px' }}>You don't own any NFT</Text>
        ) : (

          <Grid columns="3" gap="1" width="auto">
            {[...nfts.values()].map((nft, index) => (
              <Flex
                key={index}
                className="nft-item"
                direction="column"
                align="left"
                onClick={() => navigate('/collection-detail', { state: { nft } })}
                style={{ cursor: 'pointer' }}
              >
                <img src={nft.image} alt={nft.name} className="nft-image" />
                <Text size="3" style={{ marginTop: '10px' }}>{truncate(nft.name, 15)}</Text>
                <Text size="1" style={{ marginTop: '5px' }}>{truncate(nft.description, 20)}</Text>
              </Flex>
            ))}
          </Grid>

        )}
      </Flex>
    </Flex>
  );
};

export default Collection;