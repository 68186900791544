import React, { useState, useEffect } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { Flex, Text } from '@radix-ui/themes';
import { CheckCircledIcon } from '@radix-ui/react-icons';
import ethereumIcon from '../images/ethereum.svg';
import polygonIcon from '../images/polygon.svg';
import arbitrumIcon from '../images/arbitrum.svg';
import baseIcon from '../images/base.svg';
import sepoliaIcon from '../images/sepolia.svg';
import '../css/Modal.css';

const ChainModal = ({ onClose, onSelectChain }) => {
  const chains = [
    { name: 'Ethereum', icon: ethereumIcon },
    { name: 'Polygon', icon: polygonIcon },
    { name: 'Arbitrum', icon: arbitrumIcon },
    { name: 'Base', icon: baseIcon },
    { name: 'Sepolia', icon: sepoliaIcon },
  ];

  const [selectedChain, setSelectedChain] = useState(localStorage.getItem('selectedChain') || 'Ethereum');

  const handleChainSelect = (chainName) => {
    setSelectedChain(chainName);
    localStorage.setItem('selectedChain', chainName);
    onSelectChain(chainName);
  };

  return (
    <Dialog.Root open={true} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContent">
          <Dialog.Title style={{ display: 'none' }}>---</Dialog.Title>
          <Flex direction="column" align="center" justify="between" style={{ height: '100%' }}>
            <Flex direction="row" wrap="wrap" gap="2" justify="center">
              {chains.map((chain) => (
                <Flex
                  direction="row"
                  justify="between"
                  key={chain.name}
                  onClick={() => handleChainSelect(chain.name)}
                  style={{ width: '100%', alignItems: 'center', padding: '10px', margin: '5px', cursor: 'pointer' }}
                  className="radix-button"
                >
                  <Flex direction="row" align="center">
                    <img src={chain.icon} alt={`${chain.name} icon`} style={{ width: '30px', height: '30px' }} />
                    <Text size="small" style={{ paddingLeft: "10px" }}>{chain.name}</Text>
                  </Flex>
                  {selectedChain === chain.name && (
                    <CheckCircledIcon style={{ width: 30, height: 30 }} color='green' />
                  )}
                </Flex>
              ))}
            </Flex>
          </Flex>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default ChainModal;