import React, { useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { Flex, Button } from '@radix-ui/themes';
import ReactQrReader from 'react-qr-reader-es6';
import '../css/Modal.css';

const ConnectModal = ({ onClose }) => {
  const [scanResult, setScanResult] = useState('');
  const [loadingScan, setLoadingScan] = useState(false);
  const [destinationConnect, setDestinationConnect] = useState('');

  const handleScan = (scanData) => {
    if (scanData) {
      console.log(`Loaded data:`, scanData);
      setScanResult(scanData);
      setLoadingScan(false);
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const handleConnect = () => {
    // Handle connect logic here
    console.log(`connecting`);
    onClose();
  };

  return (
    <Dialog.Root open={true} onOpenChange={onClose}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content className="DialogContent">
          <Dialog.Title style={{ display: 'none' }}>---</Dialog.Title>
          <Flex direction="column" align="center" justify="between" style={{ height: '100%' }}>
            <ReactQrReader
              delay={300}
              onError={handleError}
              onScan={handleScan}
              style={{ width: "100%"}}
            />

            <Flex direction="column" style={{width: "100%", paddingTop: "10px"}}>
                <input
                    type="text"
                    placeholder="wc:your_wallet_connect_id"
                    value={destinationConnect}
                    onChange={(e) => setDestinationConnect(e.target.value)}
                    className="input-field"
                />
              <button onClick={handleConnect} className="send-button">
                Connect
              </button>
            </Flex>
          </Flex>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default ConnectModal;
