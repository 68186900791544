import axios from 'axios';
import Web3 from 'web3';

const alchemyProjectId = process.env.REACT_APP_ALCHEMY_PROJECT_ID;
const cryptoCompareApiKey = process.env.REACT_APP_CRYPTOCOMPARE_API_KEY;

const web3Ethereum = new Web3(new Web3.providers.HttpProvider(`https://eth-mainnet.g.alchemy.com/v2/${alchemyProjectId}`));
const web3Polygon = new Web3(new Web3.providers.HttpProvider(`https://polygon-mainnet.g.alchemy.com/v2/${alchemyProjectId}`));
const web3Base = new Web3(new Web3.providers.HttpProvider(`https://base-mainnet.g.alchemy.com/v2/${alchemyProjectId}`));
const web3Arb = new Web3(new Web3.providers.HttpProvider(`https://arb-mainnet.g.alchemy.com/v2/${alchemyProjectId}`));
const web3Sepolia = new Web3(new Web3.providers.HttpProvider(`https://eth-sepolia.g.alchemy.com/v2/${alchemyProjectId}`));

const getPrices = async () => {
  const response = await axios.get(`https://min-api.cryptocompare.com/data/pricemulti?fsyms=ETH,MATIC,ARB,BASE&tsyms=USD&api_key=${cryptoCompareApiKey}`);
  return response.data;
};

const getBalance = async (address, chain) => {
  let balance;
  if (chain === 'ETH') {
    balance = await web3Ethereum.eth.getBalance(address);
  } else if (chain === 'MATIC') {
    balance = await web3Polygon.eth.getBalance(address);
  } else if (chain === 'BASE') {
    balance = await web3Base.eth.getBalance(address);
  } else if (chain === 'ARB') {
    balance = await web3Arb.eth.getBalance(address);
  } else if (chain === 'SEPOLIA') {
    balance = await web3Sepolia.eth.getBalance(address);
  } else {
    balance = 0;
  }
  return parseFloat(web3Ethereum.utils.fromWei(balance, 'ether'));
};

export const fetchData = async (address) => {
  const prices = await getPrices();
  const balances = {
    ETH: await getBalance(address, 'ETH'),
    MATIC: await getBalance(address, 'MATIC'),
    ARB: await getBalance(address, 'ARB'),
    BASE: await getBalance(address, 'BASE'),
    SEPOLIA: await getBalance(address, 'SEPOLIA'),
  };

  return { prices, balances };
};
