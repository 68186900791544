import React from 'react';
import { Flex, Button } from '@radix-ui/themes';
import { useNavigate } from 'react-router-dom';
import { WebAuthn } from 'othello-webauthn-wallet';

const Login = () => {
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const challenge = new Uint8Array(Buffer.from("random", 'hex'));
      const credential = await WebAuthn.get(challenge);
      if (credential) {
        console.log('Login successful:', credential.walletAddress);
        localStorage.setItem("walletAddress", credential.walletAddress);
        navigate('/home', { replace: true });
        window.dispatchEvent(new Event('storage'));
      } else {
        alert('Login failed. Please try again.');
      }
    } catch (error) {
      console.error("Error during login:", error);
      alert("Login failed. Please try again.");
    }
  };

  return (
    <Flex direction="column" justify="center" style={{ flex: 1, width: '90%' }}>
      <Button onClick={handleLogin} size="3" radius="full">Login</Button>
    </Flex>
  );
};

export default Login;
