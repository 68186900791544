import React, { useState } from 'react';
import { Flex, Button, TextField } from '@radix-ui/themes';
import { useNavigate } from 'react-router-dom';
import { WebAuthn } from 'othello-webauthn-wallet';

const Signup = () => {
  const [username, setUsername] = useState('');
  const navigate = useNavigate();

  const urlType = process.env.REACT_APP_ENDPOINT_TYPE;

  const handleSignup = async () => {
    try {
      const credential = await WebAuthn.create(username);
      if (credential) {
        const walletAddress = credential.walletAddress;
        localStorage.setItem("walletAddress", walletAddress);

        const response = await fetch(`https://api.oth3llo.${urlType}/${urlType}/inputOth3lloCustomer`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'x-api-key': 's0aPM75bkT1COlcBaOHJ71mOMeReWRJz5UYkmop2'
          },
          body: JSON.stringify({ 
            "walletAddress": walletAddress,
            "tormoId": "",
            "publicKeyX": "",
            "publicKeyY": ""
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to register wallet address');
        }

        console.log('Signup successful:', walletAddress);
        navigate('/home', { replace: true });
        window.dispatchEvent(new Event('storage'));
      } else {
        alert("Failed to create wallet. Please try again.");
      }
    } catch (error) {
      console.error("Error creating wallet:", error);
      alert("Signup failed. Please try again.");
    }
  };

  return (
    <Flex direction="column" justify="center" style={{ flex: 1, width: '90%' }}>
      <TextField.Root 
        size="3"
        placeholder="Enter your username"
        value={username} 
        onChange={(e) => setUsername(e.target.value)} 
        style={{ marginBottom: '10px' }}
        radius="full"
      />
      <Button onClick={handleSignup} size="3" radius="full">Signup</Button>
    </Flex>
  );
};

export default Signup;