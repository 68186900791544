import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Theme } from '@radix-ui/themes';
import '@radix-ui/themes/styles.css'; // Import global CSS
import { ThemeProvider } from './context/ThemeContext';

const rootElement = document.getElementById('root');

const renderMethod = rootElement.hasChildNodes() ? ReactDOM.hydrate : ReactDOM.render;

const root = renderMethod(
  <React.StrictMode>
    <ThemeProvider>
      <Theme>
        <App />
      </Theme>
    </ThemeProvider>
  </React.StrictMode>,
  rootElement
);

export default root;