import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Flex, Button, Text } from '@radix-ui/themes';
import TopBar from '../components/TopBar';
import WalletAddress from '../components/WalletAddress';
import Portfolio from '../components/Portfolio';
import BottomBar from '../components/BottomBar';
import { PaperPlaneIcon, Crosshair1Icon, CheckIcon } from '../components/RadixIcons';
import SendModal from '../components/SendModal';
import ConnectModal from '../components/ConnectModal';
import GetModal from '../components/GetModal';
import SignModal from '../components/SignModal';
import QuantityModal from '../components/QuantityModal';
import PhantomMintModal from '../components/PhantomMintModal';
import '../css/App.css';

const Home = ({ address }) => {
  const walletAddress = address;
  const location = useLocation();
  const navigate = useNavigate();

  const [showSendModal, setShowSendModal] = useState(false);
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [showGetModal, setShowGetModal] = useState(false);
  const [showSignModal, setShowSignModal] = useState(false);
  const [showQuantityModal, setShowQuantityModal] = useState(false);
  const [showPhantomMintModal, setShowPhantomMintModal] = useState(false);
  const [nftMintEventUid, setNftMintEventUid] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [price, setPrice] = useState(0);
  const [paramsChecked, setParamsChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [signingError, setSigningError] = useState('');

  const hasSubmittedRef = useRef(false); // Ref to track if submission has occurred

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const stripeMint = params.get('stripeMint');
    const uid = params.get('nftMintEventUid');
    const imageUrl = params.get('imageUrl');
    const price = params.get('price');

    if (stripeMint === 'true' && uid) {
      console.log('Setting UID:', uid);
      setNftMintEventUid(uid);
      setImageUrl(imageUrl);
      setPrice(Number(price));
      setShowQuantityModal(true);
    }
  }, [location]);

  useEffect(() => {
    if (paramsChecked) return; // Prevent the effect from running multiple times

    const params = new URLSearchParams(location.search);
    const stripeMintDone = params.get('stripe_mint_done');
    const phantomMint = params.get('phantom_mint');
    const walletAddressParam = params.get('wallet_address');
    const nftMintEventUid = params.get('nft_mint_event_uid');
    const stripeId = params.get('session_id');

    console.log('Checking Params:', { stripeMintDone, phantomMint, walletAddressParam, nftMintEventUid, stripeId });

    if (!paramsChecked) {
      if (stripeMintDone === 'true' && walletAddressParam && nftMintEventUid && stripeId) {
        const urlType = process.env.REACT_APP_ENDPOINT_TYPE;
        const requestData = {
          nftMintEventUid,
          walletAddress: walletAddressParam,
          stripeId
        };

        setParamsChecked(true); // Mark parameters as checked
        makeRequest(requestData, `https://api.oth3llo.${urlType}/${urlType}/inputOth3lloCustomerPurchaseHistory`);
      } else if (phantomMint === 'true' && walletAddress && nftMintEventUid) {
        console.log('Phantom Mint:', { nftMintEventUid, walletAddress });
        setShowPhantomMintModal(true); // Show the mint modal
        setParamsChecked(true); // Mark parameters as checked
      }
    }
  }, [location, walletAddress, paramsChecked]);

  const makeRequest = async (requestData, url) => {
    try {
      setLoading(true);
      setSigningError('');
      setSuccess(false);
      console.log('Requesting:', requestData);
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 's0aPM75bkT1COlcBaOHJ71mOMeReWRJz5UYkmop2'
        },
        body: JSON.stringify(requestData)
      });

      if (response.ok) {
        console.log("Succeed");
        setSuccess(true);
        window.location.href = 'https://oth3llo.io/'; // Navigate to root and clear the URL parameters
      } else {
        const error = await response.json();
        console.error('Error submitting phantom mint:', error);
        setSigningError(error.message);
        window.location.href = 'https://oth3llo.io/'; // Navigate to root and clear the URL parameters
      }
    } catch (error) {
      console.error('Fetch error:', error);
      setSigningError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleMintNow = () => {
    const params = new URLSearchParams(location.search);
    const nftMintEventUid = params.get('nft_mint_event_uid'); // have to search params again for using usestate
    console.log('Mint Now Clicked with UID:', nftMintEventUid);
    const urlType = process.env.REACT_APP_ENDPOINT_TYPE;
    const requestData = {
      nftMintEventUid,
      walletAddress
    };
    console.log('Minting Now with:', requestData);
    makeRequest(requestData, `https://api.oth3llo.${urlType}/${urlType}/inputOth3lloMint`);
  };

  const handleCheckout = async (quantity) => {
    const urlType = process.env.REACT_APP_ENDPOINT_TYPE;

    const requestData = {
      nftMintEventUid,
      walletAddress,
      quantity
    };

    console.log('Request Data:', JSON.stringify(requestData));

    try {
      const response = await fetch(`https://api.oth3llo.${urlType}/${urlType}/createOth3lloCheckoutSession`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-api-key': 's0aPM75bkT1COlcBaOHJ71mOMeReWRJz5UYkmop2'
        },
        body: JSON.stringify(requestData)
      });

      const data = await response.json();

      if (response.ok) {
        console.log('Response Data:', data);
        if (data.checkoutUrl) {
          window.location.href = data.checkoutUrl;
        }
      } else {
        console.error('Error:', data);
      }
    } catch (error) {
      console.error('Fetch Error:', error);
    }
  };

  return (
    <Flex direction="column" className="flex-column" style={{ height: '100vh', backgroundColor: 'var(--background-color)', color: 'var(--text-color)' }}>
      <TopBar />

      <Flex direction="row" align="center" justify="center" className="button-grid" gap="6" style={{ marginTop: '30px' }}>
        <Flex direction="column" align="center" justify="center">
          <WalletAddress address={walletAddress} style={{ marginTop: '40px' }} />

          <Flex direction="row" gap="6" style={{ marginTop: '40px' }}>
            <Flex direction="column" align="center">
              <Button 
                className="radix-button"
                style={{
                  alignItems: "center",
                  width: "2.5rem",
                  height: "2.5rem"
                }}
                onClick={() => setShowSendModal(true)}
              >
                <Flex direction="column" align="center">
                  <PaperPlaneIcon style={{ width: 20, height: 20 }} />
                </Flex>
              </Button>
              <Text size={"1"} style={{ marginTop: "0.3rem", alignItems: "center"}}>Send</Text>
            </Flex>

            <Flex direction="column" align="center">
              <Button 
                className="radix-button"
                style={{
                  alignItems: "center",
                  width: "2.5rem",
                  height: "2.5rem"
                }}
                onClick={() => setShowGetModal(true)}
              >
                <Flex direction="column" align="center">
                  <Crosshair1Icon style={{ width: 20, height: 20 }} />
                </Flex>
              </Button>
              <Text size={"1"} style={{ marginTop: "0.3rem", alignItems: "center"}}>Get</Text>
            </Flex>

            <Flex direction="column" align="center">
              <Button 
                className="radix-button"
                style={{
                  alignItems: "center",
                  width: "2.5rem",
                  height: "2.5rem"
                }}
                onClick={() => setShowSignModal(true)}
              >
                <Flex direction="column" align="center">
                  <CheckIcon style={{ width: 20, height: 20 }} />
                </Flex>
              </Button>
              <Text size={"1"} style={{ marginTop: "0.3rem", alignItems: "center"}}>Check</Text>
            </Flex>

          </Flex>
        </Flex>
      </Flex>

      <Portfolio address={walletAddress} /> {/* Portfolio */}

      {showSendModal && <SendModal walletAddress={walletAddress} onClose={() => setShowSendModal(false)} />}
      {showConnectModal && <ConnectModal onClose={() => setShowConnectModal(false)} />}
      {showGetModal && <GetModal walletAddress={walletAddress} onClose={() => setShowGetModal(false)} />}
      {showSignModal && <SignModal walletAddress={walletAddress} onClose={() => setShowSignModal(false)} />}
      {showQuantityModal && <QuantityModal walletAddress={walletAddress} onClose={() => setShowQuantityModal(false)} onProceed={handleCheckout} imageUrl={imageUrl} price={price} />}
      {showPhantomMintModal && <PhantomMintModal onClose={() => setShowPhantomMintModal(false)} onProceed={handleMintNow} loading={loading} success={success} signingError={signingError} />} {/* Phantom Mint Modal */}
    </Flex>
  );
};

export default Home;
